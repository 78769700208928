import ConfiguratorLayout from "components/Configurator/Layout"
import ConfiguratorProducts from "views/ConfiguratorProducts"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"

type DataProps = {}

const ConfiguratorProductsPage: React.FC<PageProps<DataProps>> = () => (
  <ConfiguratorLayout activePageId="products">
    <SEO
      title="Konfigurátor vypínačů Schneider Electric"
      description="Nakonfigurujte si vypínače a zásuvky přesně podle vašich představ. Široká nabídka stylových vypínačů a zásuvek pro jakýkoliv interiér."
    />
    <ConfiguratorProducts />
  </ConfiguratorLayout>
)

export default ConfiguratorProductsPage
